import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  DestroyRef,
  HostBinding,
  inject,
  Input,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageLoaderStatus } from '@nexuzhealth/shared/ui-toolkit/loading-states/page-loader';
import {
  EmptyDefDirective,
  ErrorDefDirective,
  LoadingDefDirective,
  LoadingStatus,
  toLoadingStatus,
} from '@nexuzhealth/shared/ui-toolkit/loading2';
import { UiCardService } from '../../service/ui-card.service';

@Component({
  selector: 'nxh-card-body',
  templateUrl: './card-body.component.html',
  styleUrls: ['./card-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBodyComponent implements OnInit {
  @HostBinding('class')
  _hostClasses = 'card-body nxh-card-component';
  @HostBinding('class.nxh-card-body-no-top-padding')
  _noTopPadding!: boolean;

  @HostBinding('class.nxh-card-body-no-padding')
  @Input({ transform: booleanAttribute })
  noPadding = false;

  @HostBinding('class.nxh-card-body-dark')
  @Input()
  dark = false;

  @Input({ transform: toLoadingStatus }) loadingState?: LoadingStatus = null;

  @ContentChild(EmptyDefDirective, { read: TemplateRef, descendants: false })
  protected emptyTemplate: TemplateRef<any>;
  @ContentChild(ErrorDefDirective, { read: TemplateRef, descendants: false })
  protected errorTemplate: TemplateRef<any>;
  @ContentChild(LoadingDefDirective, { read: TemplateRef, descendants: false })
  protected loadingTemplate: TemplateRef<any>;

  private _uiCardService: UiCardService = inject(UiCardService, { optional: true });
  private _destroyRef: DestroyRef = inject(DestroyRef);

  @HostBinding('class.nxh-card-body--loaded')
  get loaded() {
    return !this.loadingState || this.loadingState === PageLoaderStatus.loaded;
  }

  ngOnInit(): void {
    this._uiCardService?.cardBodyNoTopPadding$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((value) => (this._noTopPadding = value));
  }
}
