import { Facet as InsurabilityFacet } from '@nexuzhealth/shared/finance/data-access-insurability';
import { ComponentSettings } from '@nexuzhealth/shared/settings/data-access-settings';
import { EFactStatus } from '@nexuzhealth/shared/finance/domain';

export const componentsConfig: ComponentSettings = {
  components: {
    'care-plan-form': {
      partsSelectedByDefault: false,
    },
    'patient-page': {
      loadOpenContact: false,
    },

    'patient-records-page': {
      displayedFields: [
        'given',
        'family',
        'nick',
        'assignedIds',
        'mutuality',
        'registrationNumber',
        'birthDate',
        'deathDate',
        'administrativeGender',
        'maritalStatus',
        'nationalities',
        'languages',
        'profession',
        'communicationPreference',
      ],
      validatePersonDataVisible: false,
    },

    patient: {
      'search-full': {
        displayedColumns: ['patient', 'inss', 'phoneNumber', 'birthDay', 'home-address', 'actions'],
        simpleSearchEnabled: true,
        advancedSearchEnabled: true,
        allPatientsSearchEnabled: true,
      },
      defaultPatientPageForClinical: ['care-plan-dashboard'],
    },
    'health-element-page': {
      showValidationStatus: true,
      showValidatedBy: false,
      'health-elements-title': '_ehrbase._health-element-overview.title-hn',
      motivationsRequired: true,
    },
    'medication-page': {
      attributes: {
        frequency: {
          required: true,
        },
      },
    },
    'medication-widget': {
      includeProposals: true,
    },
    'patient-entourage': {
      showExtraIdentifiers: true,
    },
    'administrative-dossier': {
      insurability: {
        facets: [
          InsurabilityFacet.insurability,
          InsurabilityFacet.carePath,
          InsurabilityFacet.gmf,
          InsurabilityFacet.palliative,
          InsurabilityFacet.hospital,
          InsurabilityFacet.generalsituation,
        ],
      },
    },
    sumehr: {
      canExport: false,
    },
    katz: {
      showNewToiletRequestButton: true,
    },
    sidebar: {
      summary: {
        displayedWidgets: ['chronic-medication'],
      },
    },
    finance: {
      efact: {
        allowMarkAsRefused: true,
        allowChangeAndRebill: false,
        allowCreditCertificate: false,
        allowCreditAndRebill: true,
        allowResubmitCredit: true,
        showAttestButton: false,
        showErrorsOnlyDefaultValue: false,
        showInvoiceMonth: true,
        processingEFactStatuses: [EFactStatus.preparing, EFactStatus.sent],
        usesCertificate: false,
      },
      labelOverrides: {
        releaseInvoice: {
          tooltip: '_invoice._retry-rejected-hn.tooltip',
          success: '_invoice._retry-rejected-hn.success',
          failed: '_invoice._retry-rejected-hn.failed',
        },
        efactEmptyTable: '_loading-states.no-items-found',
      },
    },
    tricNavBarLimitedByMemberdataDeceased: true,
    parameters: {
      showObservationFunctionality: true,
    },
    messages: {
      defaultOutlets: {
        sidebar: ['observations'],
      },
    },
  },
  services: {
    healthElementQuery: {
      onlyShowValidatedHealthElements: false,
    },
  },
  //Shared routes defined in documents-routing.module.ts
  routeconfig: {
    documents: {
      routes: ['internal', ':documentName', 'ehealth-hub', 'journal'],
    },
  },
  helpCenterRecentErrorModules: [
    'Account',
    'Care',
    'Communication',
    'Invoicing',
    'Lab',
    'Medication',
    'Migration',
    'Mobile',
    'Organisation',
    'Patient management',
    'Planning',
    'Other',
  ],
};
