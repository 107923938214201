import { ChangeDetectionStrategy, Component, ContentChild, Input, Optional, Self, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { Address, PatientAddress, ResourceName } from '@nexuzhealth/shared/domain';
import { RadioGroupComponent } from '@nexuzhealth/shared/ui-toolkit/forms';
import { AddressCommentDirective } from './address-comment.directive';
import { AddressLabelDirective } from './address-label.directive';
import { AddressLineDirective } from './address-line.directive';

@Component({
  selector: 'nxh-address-selector',
  templateUrl: './address-selector.component.html',
  styleUrls: ['./address-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressSelectorComponent implements ControlValueAccessor {
  @Input() addresses: Array<PatientAddress & { disabled?: boolean }> = [];
  @Input() labelsVisible = true;
  @Input() commentVisible = true;

  /**
   * Indicates wether the given / selected value is of type ResourceName or object (i.e. the full address object)
   */
  @Input() bindType: 'object' | 'resourcename' = 'object';

  formControl = new UntypedFormControl();
  radioGroupComponent: RadioGroupComponent;

  @ContentChild(AddressLineDirective, { read: TemplateRef })
  customLineTemplate!: TemplateRef<AddressLineDirective>;
  @ContentChild(AddressLabelDirective, { read: TemplateRef })
  customLabelTemplate!: TemplateRef<AddressLabelDirective>;
  @ContentChild(AddressCommentDirective, { read: TemplateRef })
  customCommentTemplate!: TemplateRef<AddressCommentDirective>;

  onTouch!: () => void;

  constructor(@Optional() @Self() private ngControl: NgControl) {
    if (ngControl) ngControl.valueAccessor = this;
  }

  registerOnChange(fn: any): void {
    this.formControl.valueChanges.subscribe((value) => {
      if (this.bindType === 'resourcename') {
        fn(value);
      } else {
        fn(this.addresses.find((address) => address.name === value));
      }
    });
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  writeValue(obj: Address | ResourceName): void {
    if (isResourceName(obj)) {
      this.formControl.setValue(obj, { emitEvent: false });
    } else {
      this.formControl.setValue(obj?.name ?? null, { emitEvent: false });
    }
  }

  get parentControlDisabled() {
    return this.ngControl?.disabled;
  }
}

function isResourceName(obj: Address | ResourceName): obj is ResourceName {
  return typeof obj === 'string';
}
