import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '@nexuzhealth/shared/authentication/data-access-auth';
import { AccessRole, RoleExpr } from '@nexuzhealth/shared/domain';
import { NavbarItem, NavTarget } from '@nexuzhealth/shared/toolkit/feature-shell';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { FeatureFlagQuery, FeatureFlagService } from '@nexuzhealth/shared/settings/feature-flags/data-access';

@Component({
  templateUrl: './app-shell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppShellComponent implements OnInit {
  readonly AccessRole = AccessRole;
  navItems$: Observable<NavbarItem[]>;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private featureFlagService: FeatureFlagService,
    private featureFlagQuery: FeatureFlagQuery
  ) {}

  ngOnInit() {
    this.featureFlagService.loadMany('featureflags/nxh-mail', 'featureflags/multimedia_save-as-draft').subscribe();

    this.navItems$ = combineLatest([
      this.http.get<{ url: string }>('api/integrations/mynexuzhealth/v1/pro:link').pipe(
        map(({ url }) => url),
        catchError(() => of(''))
      ),
      this.featureFlagQuery.selectFeatureFlagEnabled('featureflags/nxh-mail'),
    ]).pipe(
      map(([url, nxhMailEnabled]) => {
        const navitems: Array<NavbarItem & { roleExpr?: RoleExpr }> = [
          {
            label: 'patients',
            url: '/patients',
            roleExpr: { or: [AccessRole.clerk, AccessRole.clinical] },
          },
          {
            label: '_messages.navbar.title',
            url: '/messages',
            roleExpr: nxhMailEnabled ? undefined : AccessRole.clinical,
          },
          {
            label: 'planning',
            url: '/planning',
            roleExpr: AccessRole.organisationPlanning,
          },
          {
            label: 'requests',
            url: '/requests',
            roleExpr: AccessRole.requestsApproval,
          },
          {
            label: 'billing',
            url: '/billing',
            roleExpr: AccessRole.clinical,
          },
          {
            label: '_care._catalog.navbar-title',
            url: '/catalog',
            roleExpr: AccessRole.clinical,
          },
          {
            label: 'worklists',
            url: '/worklists',
            featureFlag: 'featureflags/worklist',
          },
        ];

        if (url) {
          navitems.push({
            label: 'hospital-records',
            url: url,
            roleExpr: AccessRole.clinical,
            target: NavTarget.BLANK,
          });
        }

        return navitems.filter((item) => this.authService.isAuthorized(item.roleExpr));
      })
    );
  }
}
