import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'nxh-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalHeaderComponent {
  @Input() title: string | null;
  @Input() type: 'primary' | 'success' | 'danger' | 'warning' = 'primary';

  @HostBinding('class.nxh-modal-header-dark')
  @Input()
  dark = false;

  @HostBinding('class') get classAttribute(): string {
    const defaultClasses = ['modal-header', 'nxh-card-modal-header'];
    const typeClass = 'nxh-modal-header-' + this.type;
    return defaultClasses.concat(typeClass).join(' ');
  }

  @Output() dismiss = new EventEmitter<void>();
}
