<nh-radio-group
  [formControl]="formControl"
  [direction]="'col'"
  [noTopMargin]="true"
  #radioGroupComponent
  (blur)="onTouch()"
>
  <nh-radio
    *ngFor="let address of addresses"
    [value]="address.name"
    [disabled]="address.disabled || parentControlDisabled"
  >
    <span class="address-label" [class.disabled]="address.disabled">{{
      (address.use !== 'Use_OFFICIAL' ? '_address.extra' : '_address.domicile') | i18next
    }}</span>
    <div>
      <ng-container
        [ngTemplateOutlet]="customLineTemplate || lineTemplate"
        [ngTemplateOutletContext]="{ $implicit: address, disabled: address.disabled || parentControlDisabled }"
      ></ng-container>
      <ng-container *ngIf="labelsVisible">
        <ng-container
          [ngTemplateOutlet]="customLabelTemplate || labelTemplate"
          [ngTemplateOutletContext]="{
            $implicit: label,
            disabled: address.disabled || parentControlDisabled
          }"
          *ngFor="let label of address.labels; let i = index"
        ></ng-container>
      </ng-container>
    </div>
    <ng-container
      *ngIf="commentVisible && address.comment"
      [ngTemplateOutlet]="customCommentTemplate || commentTemplate"
      [ngTemplateOutletContext]="{
        $implicit: address.comment,
        disabled: address.disabled || parentControlDisabled
      }"
    >
    </ng-container>
  </nh-radio>
</nh-radio-group>

<ng-template #lineTemplate let-address let-disabled="disabled">
  <span class="address-line" [class.disabled]="disabled">{{ address | formatAddress }}</span>
</ng-template>

<ng-template #labelTemplate let-label let-disabled="disabled">
  <nxh-pill [type]="disabled | nxhPillType">{{ '_address._label.' + label | i18next }}</nxh-pill>
</ng-template>

<ng-template #commentTemplate let-comment let-disabled="disabled">
  <span class="address-comment">
    <fa-icon [icon]="'comment-alt-lines'"></fa-icon>
    <span [innerHtml]="comment | nl2br : true"></span>
  </span>
</ng-template>
