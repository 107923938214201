import { Routes } from '@angular/router';
import { isAuthorizedGuard } from '@nexuzhealth/shared/authentication/feature-authorization';
import { AuthGuard } from '@nexuzhealth/shared/authentication/data-access-auth';
import { ReferenceResolveService } from '@nexuzhealth/shared/reference-data/data-access';
import { AccessRole } from '@nexuzhealth/shared/domain';
import { PageNotFoundComponent } from '@nexuzhealth/shared/ui-toolkit/not-found';
import { AuthenticatedThemeResolver } from '@nexuzhealth/shared/toolkit/feature-theming';
import { FeatureFlagResolver } from '@nexuzhealth/shared/settings/feature-flags/data-access';
import { FatalErrorPageComponent } from '@nexuzhealth/shared/toolkit/feature-shell';
import { CountryConfigResolveService } from '@nexuzhealth/shared/street-management/data-access';
import { AppShellComponent } from './app-shell/app-shell.component';

export const routes: Routes = [
  {
    path: 'fatal-error',
    component: FatalErrorPageComponent,
  },
  {
    path: '',
    component: AppShellComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        redirectTo: 'patients',
        pathMatch: 'full',
      },
      {
        path: 'patients',
        loadChildren: () =>
          import('@nexuzhealth/moapr-hn/feature-patient').then((mod) => mod.MoaprHnFeaturePatientModule),
      },
      {
        path: 'planning',
        loadChildren: () =>
          import('@nexuzhealth/moapr-hn/feature-planning').then((mod) => mod.MoaprHnFeaturePlanningModule),
        canActivate: [isAuthorizedGuard(AccessRole.organisationPlanning)],
      },
      {
        path: 'messages',
        loadChildren: () =>
          import('@nexuzhealth/shared/communication/messages/feature-overview').then((m) => m.FeatureOverviewModule),
      },
      {
        path: 'requests',
        loadChildren: () =>
          import('@nexuzhealth/moapr-hn/feature-requests').then((m) => m.MoaprHnFeatureRequestsModule),
        canActivate: [isAuthorizedGuard(AccessRole.organisationPlanning)],
        data: {
          skipBreadcrumb: true,
        },
      },
      {
        path: 'billing',
        loadChildren: () => import('@nexuzhealth/moapr-hn/finance/shell').then((m) => m.MoaprHnFinanceShellModule),
        canActivate: [isAuthorizedGuard(AccessRole.clinical)],
        data: {
          breadcrumb: 'billing',
        },
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('@nexuzhealth/moapr-hn/care/feature-catalog').then((m) => m.MoaprHnCareFeatureCatalogModule),
        canActivate: [isAuthorizedGuard(AccessRole.clerk)],
        data: {
          breadcrumb: '_care._catalog.breadcrumb',
        },
      },
      {
        path: 'worklists',
        loadChildren: () => import('@nexuzhealth/shared/worklist/shell').then((m) => m.SharedWorklistShellModule),
      },
      {
        path: '',
        redirectTo: 'patients',
        pathMatch: 'full',
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: {
          breadcrumb: '_page-not-found.breadcrumb',
        },
      },
    ],
    resolve: {
      references: ReferenceResolveService,
      theme: AuthenticatedThemeResolver,
      featureFlags: FeatureFlagResolver,
      countriesWithStructuredAddresses: CountryConfigResolveService,
    },
    data: {
      flagNames: [
        'featureflags/care-rotation',
        'featureflags/ehealthbox-organisation',
        'featureflags/ehealthbox-send-via-organisation',
        'featureflags/health-elements-split-by-validation-state,',
        'featureflags/mock-eid-supported',
        'featureflags/nxh-mail',
        'featureflags/select-french',
        'featureflags/shared-inbox',
        'featureflags/tripvisit-with-dayparts',
        'featureflags/visiting-round-automatic-planner',
        'featureflags/worklist',
        'featureflags/finance-issuelist',
        'featureflags/wound-observation-disable-upload-photos',
        'featureflags/finance-unsent',
        'featureflags/finance-care-register',
        'featureflags/finance-patient-care-register',
        'featureflags/finance-revenue-report',
        'featureflags/finance-patient-revenue-report',
        'featureflags/finance-mutualities',
        'featureflags/finance-non-mutualities',
        'featureflags/finance-planned-attendances',
      ],
    },
  },
];
