import { Pipe, PipeTransform } from '@angular/core';
import { ToolkitTypeValue } from '@nexuzhealth/shared/ui-toolkit/pill';

@Pipe({
  name: 'nxhPillType',
})
export class PillTypePipe implements PipeTransform {
  transform(disabled = false): ToolkitTypeValue | null {
    if (disabled) {
      return 'disabled';
    }
    return null;
  }
}
