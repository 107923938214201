import { ChangeDetectionStrategy, Component, LOCALE_ID, OnInit } from '@angular/core';
import { UserPreferencesQuery } from '@nexuzhealth/shared/settings/data-access-user-preferences';
import { SharedShellConfigService, ThemeService } from '@nexuzhealth/shared/toolkit/feature-shell';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'nxh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    NgbTooltipConfig,
    {
      provide: LOCALE_ID,
      deps: [UserPreferencesQuery], //some service handling global settings
      useFactory: (userPref) => userPref.getPreferredLanguage(), //returns locale string
    },
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private themeSettings: ThemeService,
    tooltipConfig: NgbTooltipConfig,
    sharedShellConfig: SharedShellConfigService
  ) {
    sharedShellConfig.setup(tooltipConfig, { persistRecentPatients: true });
  }

  ngOnInit(): void {
    this.themeSettings.getThemeSettings();
  }
}
